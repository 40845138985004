import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { preloadAssets } from "./preloadAssets";
import { UserProvider } from "./providers/user.provider";
import reportWebVitals from "./reportWebVitals";
import "./reset.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

declare global {
  interface Window {
    _env_: any;
  }
}

preloadAssets();

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain={window._env_.REACT_APP_AUTH0_DOMAIN!}
      clientId={window._env_.REACT_APP_AUTH0_CLIENTID!}
      scope={window._env_.REACT_APP_AUTH0_SCOPE!}
      audience={window._env_.REACT_APP_AUTH0_AUDIENCE!}
      redirectUri={window.location.origin}
    >
      <UserProvider>
        <App />
      </UserProvider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.unregister();
