import styled from "styled-components";
import { Loader } from "@gle/base-ui.loader";

const Animation = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--primary-color);

  svg {
    width: 50px;
    transition: all 0.5s;
    margin-bottom: 20px;
  }
`;

export const LoadingAnimation = () => {
  return (
    <Animation>
      <svg viewBox="0 0 312 598" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 534.5V456.5L157 434L0 395.5V395V365.5L235.5 422.5V389L0 332V43.5L311.5 0V491L0 534.5ZM235.5 308.5V152.5L109 170V142L235.5 124V88.5L76 111V270L235.5 308.5ZM0 598V570.5L311.5 527V554.5L0 598Z"
          fill="white"
        />
      </svg>
      <Loader color="white" />
    </Animation>
  );
};
