export const preloadAssets = () => {
  const pictures: string[] = [
    `complete_icon.png`,
    `colors_icon.png`,
    `share_icon.png`,
    `computer_icon.png`,
    `import_icon.png`,
    `fees_icon.png`,
    `medical.png`,
    `documents.png`,
    `add_icon.png`,
    `athletes_group_icon.png`,
    `athletes_icon.png`,
    `coach_icon.png`,
    `club_icon.png`,
    `invite_icon.png`,
    `coach_icon.png`,
    "comunicate_icon.png",
    "documents.png",
    "g_shirt_icon.png",
    "golee_red.png",
    "golee_white.png",
    "logout_icon.png",
    "manager_icon.png",
    "notification_icon.png",
    "notifs_icon.png",
    "share_icon.png",
    "team_icon.png",
    "shirt_icon.png",
    "error_icon.png",
  ];

  pictures.forEach((picture) => {
    const img = new Image();
    img.src = `${window._env_.PUBLIC_URL}images/${picture}`;
  });
};
