import { Theme } from "@gle/base-ui.theme-provider";
import { ConanProvider } from "@golee/gle-conan-tracker";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./AppRouter";
import { LoadingAnimation } from "./components/LoadingAnimation/LoadingAnimation";
import { useUserContext } from "./providers/user.provider";

const App = () => {
  const { isLoading, user } = useUserContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Theme>
      {isLoading ? (
        <LoadingAnimation />
      ) : (
        <ConanProvider
          distinctId={user?.refProfile!}
          baseUrl={`${window._env_.REACT_APP_API_URL}conan/`}
          defaultProperties={{
            golee_club: user?.refOrganization,
          }}
        >
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </ConanProvider>
      )}
    </Theme>
  );
};

export default App;
