import client from "./../client";

export const getUserPermissions = async (auth0_user: string): Promise<any> => {
  try {
    const response = await client.get(
      `guardian/userpermissions/user/${auth0_user}`
    );
    return response.data;
  } catch (e) {
    throw e;
  }
};
