import { Carousel } from "../../components/Carousel/Carousel";
import { ProLayout } from "../../components/Layout/Pro";

export const ProPage = () => {
  return (
    <ProLayout>
      <Carousel
        color="white"
        slides={[
          {
            imageUrl: `${window._env_.PUBLIC_URL}images/computer_icon.png`,
            title: "Puoi fare molto di più!",
            content:
              "Accedi utilizzando il tuo computer e scopri tutte le funzionalità che Golee offre alla tua società sportiva.",
          },
          {
            imageUrl: `${window._env_.PUBLIC_URL}images/import_icon.png`,
            title: "Importa i tuoi tesserati",
            content:
              "Puoi utilizzare un file Excel o caricare direttamente il modulo pdf della LND.",
          },
          {
            imageUrl: `${window._env_.PUBLIC_URL}images/medical.png`,
            title: "Visite Mediche",
            content:
              "Gestisci date di scadenza, ricevi email di preavviso e notifica i genitori in automatico.",
          },
          {
            imageUrl: `${window._env_.PUBLIC_URL}images/fees_icon.png`,
            title: "Quote di iscrizione",
            content:
              "Rendiconta ogni entrata e genera le ricevute intestate al genitore per ogni pagamento.",
          },
          {
            imageUrl: `${window._env_.PUBLIC_URL}images/documents.png`,
            title: "Stampe e modulistica",
            content:
              "Genera moduli di iscrizione, certificazioni, dichiarazioni del 730 già compilate con un click.",
          },
        ]}
      />
    </ProLayout>
  );
};
