import { CheckBox } from "../CheckBox";
import { useNavigate } from "react-router-dom";
import { logos } from "../../utils";

type Props = {
  backgroundColor?: string;
  cardColor?: string;
  children: React.ReactNode;
  header: React.ReactNode;
  topBar?: React.ReactNode;
};

export const MainLayout = ({
  backgroundColor = "var(--primary-color)",
  cardColor = "var(--white-color)",
  ...props
}: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          background: backgroundColor,
          padding: "0 20px 30px",
        }}
      >
        <div
          style={{
            height: 60,
            marginBottom: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {props.topBar ? (
            props.topBar
          ) : (
            <>
              <img
                src={logos.white}
                alt="Golee"
                style={{
                  height: 30,
                }}
              />
              <CheckBox
                label="PRO"
                onChange={() => {
                  navigate("/pro");
                }}
              />
            </>
          )}
        </div>

        <div
          style={{
            height: 60,
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {props.header}
        </div>
      </div>

      <div
        style={{
          marginTop: -20,
          width: "100%",
          background: cardColor,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          boxShadow: "0 -5px 5px 0 rgba(0,0,0,0.10)",
          padding: 20,
        }}
      >
        {props.children}
      </div>
    </>
  );
};
