import { TelegramButton } from "@gle/base-ui.buttons.social.telegram-button";
import { Heading } from "@gle/base-ui.typography.heading";
import { Text } from "@gle/base-ui.typography.text";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { countUnreadNotifications } from "../../api/hermes/notifications";
import { MainLayout } from "../../components/Layout/Main";
import { SquareCard } from "../../components/SquareCard/SquareCard";
import { useUserContext } from "../../providers/user.provider";

const icons = {
  club: `${window._env_.PUBLIC_URL}images/club_icon.png`,
  notifications: `${window._env_.PUBLIC_URL}images/notification_icon.png`,
  athletes: `${window._env_.PUBLIC_URL}images/athletes_icon.png`,
  teams: `${window._env_.PUBLIC_URL}images/team_icon.png`,
  invite: `${window._env_.PUBLIC_URL}images/add_icon.png`,
  logout: `${window._env_.PUBLIC_URL}images/logout_icon.png`,
};

export const HomePage = () => {
  const { user } = useUserContext();

  const navigate = useNavigate();

  return (
    <MainLayout
      header={
        <>
          <Heading level={3} color="white">
            Ciao {user?.name}
          </Heading>
          <Text weight="light" color="white" size="l">
            Cosa vuoi fare oggi?
          </Text>
        </>
      }
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: 20,
        }}
      >
        <SquareCard onClick={() => navigate("/club")}>
          <img src={icons.club} style={{ width: "80%" }} />
          <Text size="l" weight="medium">
            Club
          </Text>
        </SquareCard>

        <SquareCard onClick={() => navigate("/teams")}>
          <img src={icons.teams} style={{ width: "80%" }} />
          <Text size="l" weight="medium">
            Squadre
          </Text>
        </SquareCard>

        <SquareCard onClick={() => navigate("/athletes")}>
          <img src={icons.athletes} style={{ width: "80%" }} />
          <Text size="l" weight="medium">
            Atleti
          </Text>
        </SquareCard>

        <Notifications />

        <SquareCard onClick={() => navigate("/invite")}>
          <img src={icons.invite} style={{ width: "80%" }} />
          <Text size="l" weight="medium">
            Invita
          </Text>
        </SquareCard>

        <SquareCard onClick={() => navigate("/settings")}>
          <img src={icons.logout} style={{ width: "80%" }} />
          <Text size="l" weight="medium">
            Impostazioni
          </Text>
        </SquareCard>
      </div>

      <div
        className="telegram-wrap"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        <TelegramButton url="https://t.me/goleemanager">
          Entra nel nostro canale Telegram!
        </TelegramButton>
      </div>
    </MainLayout>
  );
};

const Notifications = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();

  const [count, setCount] = useState<number>();

  const loadCounter = async () => {
    try {
      const counter = await countUnreadNotifications(user?.auth0_user!);
      setCount(counter);
    } catch (err) {}
  };

  useEffect(() => {
    loadCounter();
  });

  return (
    <SquareCard onClick={() => navigate("/notifications")}>
      {count ? (
        <div
          style={{
            position: "absolute",
            color: "#fff",
            fontSize: 15,
            top: "50%",
            transform: "translateY(-30px)",
            fontWeight: 500,
          }}
        >
          {count}
        </div>
      ) : null}

      <img src={icons.notifications} style={{ width: "80%" }} />
      <Text size="l" weight="medium">
        Notifiche
      </Text>
    </SquareCard>
  );
};
