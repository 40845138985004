import { EConanEvent } from "@golee/conan-sdk";
import { useConan } from "@golee/gle-conan-tracker";
import { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { LoadingLayout } from "./components/Layout/Loading";
import Home from "./pages/Home";
import Pro from "./pages/Pro";

const Club = lazy(() => import("./pages/Club"));
const Groups = lazy(() => import("./pages/groups/list"));
const Group = lazy(() => import("./pages/groups/detail"));
const Athletes = lazy(() => import("./pages/Athletes/List"));
const Athlete = lazy(() => import("./pages/Athletes/Detail"));
const Notifications = lazy(() => import("./pages/Notifications"));
const Invite = lazy(() => import("./pages/Invite"));
const Settings = lazy(() => import("./pages/Settings"));

export const AppRouter = () => {
  const location = useLocation();

  const { trackEvent } = useConan();

  useEffect(() => {
    trackEvent(EConanEvent.PAGE_VIEW, {
      path: location.pathname,
    });
  }, [location]);

  return (
    <Suspense fallback={<LoadingLayout />}>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/pro" element={<Pro />} />

        <Route path="/club" element={<Club />} />

        <Route path="/teams" element={<Groups />} />
        <Route path="/teams/detail/:groupId" element={<Group />} />
        <Route path="/teams/detail/:groupId/:athleteId" element={<Athlete />} />

        <Route path="/athletes" element={<Athletes />} />
        <Route path="/athletes/detail/:athleteId" element={<Athlete />} />

        <Route path="/notifications" element={<Notifications />} />

        <Route path="/settings" element={<Settings />} />

        <Route path="/invite" element={<Invite />} />
      </Routes>
    </Suspense>
  );
};
