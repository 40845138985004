import * as uuid from "short-uuid";
import client from "./client";

export const authenticateClients = (token: string, role_id: string) => {
  const clientId = uuid.generate();

  client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  client.defaults.headers.common["x-gle-role"] = role_id;
  client.defaults.headers.common["x-gle-client"] = clientId;
};

export const addSeasonHeaders = (season: string) => {
  client.defaults.headers.common["x-gle-current-season"] = season;
  client.defaults.headers.common["x-gle-selected-season"] = season;
};
