import { useAuth0, User as Auth0User } from "@auth0/auth0-react";
import { createContext, useContext, useEffect, useState } from "react";
import { addSeasonHeaders, authenticateClients } from "../api";
import { getClub, getSeason } from "../api/club/club";
import { getUserPermissions } from "../api/guardian/userpermissions";
import { getProfile } from "../api/people/profile";

type User = {
  auth0_user: string;
  profileExists?: boolean;
  role_id?: string;
  email: string;
  role?: string;
  club?: {
    _id?: string;
    name: string;
    legacy_id: number;
    slug: string;
  };
  name?: string;
  refProfile?: string;
  refOrganization?: string;
};

const UserContext = createContext<{
  isAuth0Loading: boolean;
  isLoading: boolean;
  user?: User;
}>({ isLoading: true, isAuth0Loading: true });

export const UserProvider = ({ children }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const {
    isAuthenticated,
    isLoading: isAuth0Loading,
    loginWithRedirect,
    user: auth0User,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (!isAuth0Loading) {
      if (!isAuthenticated) {
        loginWithRedirect({ redirectUri: window.location.origin });
      }
    }
  }, [isAuth0Loading]);

  const loadProfile = async (auth0_user: Auth0User) => {
    const token = await getAccessTokenSilently();

    let permissions = (await getUserPermissions(auth0_user.sub!))[0];

    if (!permissions) {
      window.location.href = window._env_.REACT_APP_AUTH_APP_URL!;
      return;
    }

    authenticateClients(token, permissions.role_id);

    try {
      let profile = await getProfile();

      if (!profile) {
        window.location.href = window._env_.REACT_APP_AUTH_APP_URL!;
        return;
      }

      let club = await getClub(permissions.limitations.club[0]);
      const season = await getSeason(club?._id);

      addSeasonHeaders(season);

      setUser?.({
        auth0_user: auth0_user.sub!,
        email: auth0_user.email!,
        profileExists: !!profile,
        name: profile.name,
        refProfile: profile?._id,
        refOrganization: club?._id,
        club,
      });
    } catch (err) {
      logout({ returnTo: window._env_.REACT_APP_AUTH_APP_URL });
      return;
    }
  };

  useEffect(() => {
    if (auth0User) {
      loadProfile(auth0User);
    }
  }, [auth0User]);

  useEffect(() => {
    if (user) {
      setIsLoading(false);

      if (!user.profileExists) {
        window.location.href = window._env_.REACT_APP_AUTH_APP_URL!;
      }
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        isAuth0Loading,
        isLoading,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUserContext must be used within UserProvider");
  }

  return context;
};
