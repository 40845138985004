import { Text } from "@gle/base-ui.typography.text";
import styled from "styled-components";

export type CheckBoxProps = {
  label: string;
  onChange: (value: boolean) => void;
};

const Slider = styled.div`
  display: flex;
  align-items: center;
  > div {
    margin-left: 10px;
    position: relative;
    width: 60px;
    height: 36px;
    background: #c75954;
    border-radius: 20px;
    padding: 2px;

    > div {
      position: absolute;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background: #fff;
    }
  }
`;

const Component = ({ ...props }: CheckBoxProps) => {
  return (
    <Slider onClick={() => props.onChange?.(true)}>
      <Text color="white" size="l" weight="medium">
        {props.label}
      </Text>
      <div>
        <div></div>
      </div>
    </Slider>
  );
};

export default Component;
