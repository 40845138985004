import client from "./../client";

export const getClub = async (legacy_id: string): Promise<any> => {
  try {
    const response = await client.get(`club/club/${legacy_id}`);

    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getSeason = async (organizationId: string): Promise<string> => {
  try {
    const response = await client.get(
      `club/organizations/${organizationId}/season`
    );
    return response.data.currentSeason;
  } catch (e) {
    throw e;
  }
};
