import currency from "currency.js";

// TODO: change url
export const logos = {
  white: `${window._env_.PUBLIC_URL}images/golee_white.png`,
  red: `${window._env_.PUBLIC_URL}images/golee_red.png`,
};

export const toEuro = (value: any) => {
  return currency(value, {
    symbol: "€",
    separator: ".",
    decimal: ",",
  }).format();
};
