import { ApiQueryParams } from "../types";
import client from "./../client";

export const getNotifications = async ({
  filters,
  ...params
}: ApiQueryParams & { user?: string }): Promise<any[]> => {
  return await client.get(`hermes/notification/hist/${params.user}`, {
    params: { ...params, ...filters, start: params.skip },
  });
};

export const countUnreadNotifications = async (
  user: string,
  topic: string = "GENERAL"
): Promise<number> => {
  try {
    const response = await client.get(
      `hermes/notification/unread-count/${user}`,
      {
        params: { topic },
      }
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const readAllNotifications = async (
  user: string,
  topic: string = "GENERAL"
): Promise<void> => {
  try {
    await client.post(`hermes/notification/read-all`, {
      user,
      topic,
    });
    return;
  } catch (err) {
    throw err;
  }
};

export const readNotification = async (
  user: string,
  notification: string
): Promise<void> => {
  try {
    await client.post(`hermes/notification/read`, {
      user,
      notification,
    });
    return;
  } catch (err) {
    throw err;
  }
};
