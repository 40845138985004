import client from "../client";

export const getProfile = async (): Promise<any> => {
  try {
    const response = await client.get(`people/profiles/me`);
    return response.data;
  } catch (e) {
    if ((e as any).response.status === 404) {
      return null;
    }
    throw e;
  }
};
