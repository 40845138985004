import { useLocation, useParams } from "react-router-dom";
import {
  SkeletonImage,
  SkeletonListItem,
  SkeletonParagraph,
  SkeletonTitle,
} from "../Skeleton/Skeleton";
import { MainLayout } from "./Main";

export const LoadingLayout = () => {
  const location = useLocation();
  const params = useParams();

  const renderLocationBasedSkeleton = () => {
    if (location.pathname.includes("athletes/detail")) {
      return <DetailSkeleton />;
    }

    if (
      location.pathname.includes("athletes") ||
      location.pathname.includes("notifications")
    ) {
      return <ListSkeleton />;
    }

    return <CarouselSkeleton />;
  };

  return (
    <MainLayout header={<SkeletonTitle />}>
      {renderLocationBasedSkeleton()}
    </MainLayout>
  );
};

const DetailSkeleton = () => {
  return (
    <>
      <SkeletonListItem />
      <div style={{ marginTop: 20 }}></div>
      <SkeletonParagraph />
    </>
  );
};

const ListSkeleton = () => {
  return (
    <>
      <SkeletonListItem />
      <SkeletonListItem />
      <SkeletonListItem />
      <SkeletonListItem />
      <SkeletonListItem />
    </>
  );
};

const CarouselSkeleton = () => {
  return (
    <>
      <SkeletonImage />
      <SkeletonTitle />
      <SkeletonParagraph />
    </>
  );
};
