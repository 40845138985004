import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as ReactCarousel } from "react-responsive-carousel";
import styled from "styled-components";
import { useState } from "react";
import { Heading, HeadingProps } from "@gle/base-ui.typography.heading";
import { Text } from "@gle/base-ui.typography.text";

const Wrap = styled.div``;

type Props = {
  color?: HeadingProps["color"];
  slides: {
    imageUrl: string;
    title: string;
    content: React.ReactNode;
  }[];
};

export const Carousel = ({ ...props }: Props) => {
  const [currentItem, setCurrentItem] = useState<number>(0);

  return (
    <Wrap>
      <ReactCarousel
        autoPlay={true}
        interval={5000}
        showArrows={false}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        onChange={(index) => {
          setCurrentItem(index);
        }}
      >
        {props.slides.map((t, index) => (
          <div key={index}>
            <img src={t.imageUrl} style={{ width: 225 }} />

            <Heading level={3} style={{ marginTop: 30 }} color={props.color}>
              {t.title}
            </Heading>
            <Text weight="light" size="l" color={props.color}>
              {t.content}
            </Text>
          </div>
        ))}
      </ReactCarousel>

      <div
        style={{
          marginTop: 30,
          display: "flex",
          alignItems: "center",
          columnGap: 10,
          justifyContent: "center",
        }}
      >
        {props.slides.map((t, i) => (
          <span
            key={i}
            style={{
              display: "block",
              width: 8,
              height: 8,
              borderRadius: "50%",
              background: currentItem === i ? "#9adada" : "#f5f5f5",
            }}
          ></span>
        ))}
      </div>
    </Wrap>
  );
};
