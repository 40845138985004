export const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    fill="none"
    viewBox="0 0 24 24"
    stroke="#8c88bf"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);
