import { Heading } from "@gle/base-ui.typography.heading";
import { Text } from "@gle/base-ui.typography.text";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { logos } from "../../utils";
import { CloseIcon } from "../CloseIcon/CloseIcon";
import { MainLayout } from "./Main";

type Props = {
  children: React.ReactNode;
};

export const ProLayout = ({ ...props }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    let body = document.getElementsByTagName("body")[0];
    body.style.background = "#2f2d42";

    return () => {
      body.style.background = "#fff";
    };
  }, []);

  return (
    <MainLayout
      backgroundColor="#3F3D56"
      cardColor="#2f2d42"
      header={
        <Heading color="white" level={3}>
          Scopri Golee dal tuo computer
        </Heading>
      }
      topBar={
        <>
          <img
            src={logos.white}
            alt="Golee"
            style={{
              height: 30,
            }}
          />
          <span onClick={() => navigate(-1)}>
            <CloseIcon />
          </span>
        </>
      }
    >
      {props.children}
      <Text
        color="white"
        weight="medium"
        style={{ marginTop: 30, textAlign: "center" }}
      >
        Accedi da PC o Mac
      </Text>
    </MainLayout>
  );
};
