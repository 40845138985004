import { Card } from "@gle/base-ui.surfaces.card";

export const SquareCard = ({
  style = {},
  ...props
}: {
  style?: React.CSSProperties;
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <Card
      style={{ paddingTop: "100%", position: "relative", ...style }}
      onClick={props.onClick}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          rowGap: 10,
          padding: 5,
        }}
      >
        {props.children}
      </div>
    </Card>
  );
};
